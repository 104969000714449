//负债情况
<template>
  <div class="assetsInfo">
    <div class="head">
      <div class="addbutton" @click="addLiabilitiesInfo">
        <i class="el-icon-plus"></i>
      </div>
    </div>
    <base-table
      :columns="liabilitiesInfocolumn"
      :tableAttrs="{
        data: liabilitiesInfoData,
        stripe: true,
      }"
      :isCaculateHeight="false"
      :showPage="false"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
          <template slot="borrowerHeader">
        <font style="color: #ee1c1c">*</font>借款人
      </template>

          <template slot="isControllerHeader">
        <font style="color: #ee1c1c">*</font>是否实控人个人借款
      </template>
       <template slot="contractAmountHeader">
        <font style="color: #ee1c1c">*</font>合同金额（元）
      </template>
       <template slot="loanBalanceHeader">
        <font style="color: #ee1c1c">*</font>贷款余额（元）
      </template>
       <template slot="expiryDateHeader">
        <font style="color: #ee1c1c">*</font>到期日
      </template>
      <!-- 借款人 -->
       <template slot="borrower" slot-scope="scope">
        <el-input
          v-model="scope.row.borrower"
          placeholder="请输入借款人"
          maxlength="100"
        ></el-input>
      </template>
       <template slot="borrowingOrg" slot-scope="scope">
        <el-input
          v-model="scope.row.borrowingOrg"
          placeholder="请输入借款机构名称"
          maxlength="100"
        ></el-input>
      </template>
      <!-- 合同金额（元） -->
       <template slot="contractAmount" slot-scope="scope">
        <amount-iput
          v-model="scope.row.contractAmount"
          placeholder="请输入"
          type='10Y'
        ></amount-iput>
      </template>
      <!-- 贷款余额（元） -->
       <template slot="loanBalance" slot-scope="scope">
        <amount-iput
          v-model="scope.row.loanBalance"
          placeholder="请输入"
          type='10Y'
        ></amount-iput>
      </template>
      <!-- 到期日 -->
       <template slot="expiryDate" slot-scope="scope">
        <el-date-picker
          v-model="scope.row.expiryDate"
          type="date"
          value-format="timestamp"
          placeholder="请选择日期"
          pickerOptions:startDatePicker
          style="width: 160px;"
        >
        </el-date-picker>
      </template>
      <!-- 融资类型 -->
       <template slot="financingType" slot-scope="scope">
        <base-select
          v-model="scope.row.financingType"
          style="width: 160px; margin-right: 10px"
          :options="financingTypeOption"
          :selectedField="['dictId', 'dictName']"
           placeholder="请选择融资类型"
        ></base-select>
      </template>
       <template slot="isController" slot-scope="scope">
        <base-select
          v-model="scope.row.isController"
          style="width: 160px; margin-right: 10px"
          :options="isControllerOption"
          :selectedField="['key', 'label']"
        ></base-select>
      </template>
      <!-- 担保措施 -->
       <template slot="guaranteeMeasures" slot-scope="scope">
        <base-select
          v-model="scope.row.guaranteeMeasures"
          style="width: 160px; margin-right: 10px"
          :options="guaranteeMeasuresOption"
          :selectedField="['dictId', 'dictName']"
           placeholder="请选择担保措施"

        ></base-select>
      </template>
      <!-- 月供 -->
       <template slot="monthlyPayment" slot-scope="scope">
        <amount-iput
          v-model="scope.row.monthlyPayment"
          placeholder="请输入"
          type='10Y'
        ></amount-iput>
      </template>
      <template slot="action" slot-scope="scope">
        <icon-button
          content="删除"
          icon="iconfont iconshanchu1"
          @click="delItem(scope.$index)"
        />
      </template>
    </base-table>
  </div>
</template>
<script>
import baseTable from '@/components/common/table/base-table/base-table.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { liabilitiesInfoTable } from '../utils/enterprise-info-config'
import AmountIput from '@/components/input/amount-input/amount-input.vue'
import { getDictLists } from '@/filters/fromDict'
import BaseSelect from '@/components/common/base-select/base-select.vue'

export default {
  props: {
    tableData: Array
  },
  components: { baseTable, IconButton, AmountIput, BaseSelect },
  data () {
    return {
      isControllerOption: [{ label: '是', key: '1' }, { label: '否', key: '0' }],
      startDatePicker: {}
    }
  },
  created () {
    this.startDatePicker = {
      disabledDate (time) {
        const disabledTime = new Date('1999-12-31')
        return time.getTime() < disabledTime
      }
    }
  },
  computed: {
    liabilitiesInfocolumn () {
      return liabilitiesInfoTable(this)
    },
    financingTypeOption () {
      return getDictLists('FINANCE_TYPE')
    },
    guaranteeMeasuresOption () {
      return getDictLists('GUARANTEE_MEASURE')
    },
    liabilitiesInfoData: {
      get () {
        return this.tableData
      },
      set (val) {
        this.$emit('changeLiabilitiesInfoData', val)
      }
    }
  },
  methods: {
    // 删除数据
    delItem (index) {
      this.liabilitiesInfoData.splice(index, 1)
    },
    addLiabilitiesInfo () {
      this.liabilitiesInfoData.push({ add: true, businessId: this.$route.query.businessId })
    }
  }
}
</script>
<style lang="scss" scoped>
.assetsInfo {
  padding: 10px;
}
.textArea {
  margin-top: 20px;
}
.head {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  // 页面按钮
  .addbutton {
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      background-color: #6996FF;
    }
  }
}
.areaTitle{
  display: flex;
  font-size: 16px;
  color:#505559;
  margin-bottom: 5px;
  align-items: center;
  .enclosureUpload{
    font-size: 16px;
    margin-left: 30px;
  }
}
</style>
